<template>
  <div class="sys-user">
    <div style="width: 100%; margin-left: 10px">
      <div>
        <el-button
          type="primary"
          style="height: 40px"
          class="query-item"
          @click="addSYSUser"
        >
          添加
        </el-button>
        <el-input
          
          v-model="query.name"
          placeholder="用户名称"
          style="width: 160px;"
          class="query-item"
        ></el-input>
        <el-input
        
          v-model="query.phone"
          placeholder="用户电话"
          style="width: 160px;"
          class="query-item"
        ></el-input>
        <el-select
         
          v-model="query.type"
          placeholder="用户状态"
          style="width: 120px;"
          class="query-item"
        >
          <el-option key="1" label="开启" value="1"></el-option>
          <el-option key="0" label="禁用" value="0"></el-option>
        </el-select>
        <el-button
          type="primary"
          style="height: 40px"
          class="query-item"
          @click="handleSearch"
        >
          搜索
        </el-button>
      </div>
      <el-table
        :data="tableData"
        height="660"
        stripe
        border
        v-loading="userLoading"
        class="table"
        ref="multipleTable"
        :row-style="{ height: '10' }"
        :cell-style="{ padding: '10' }"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="index"
          label="序号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="username" label="名字" width="140" />
        <el-table-column prop="phone" label="手机号" width="140" />
        <el-table-column label="可视区域">
          <template #default="scope">
            <el-cascader
              v-if="
                scope.row.areaList != null && JSON.stringify(scope.row.areaList) != '[]'
              "
              clearable
              size="small"
              :props="{ value: 'id', label: 'name', children: 'children' }"
              placeholder="权限目录"
              :options="getTypeList(scope.row.areaList)"
              filterable
            />
            <div v-else>无可视权限</div>
          </template>
        </el-table-column>
        <el-table-column label="可视菜单">
          <template #default="scope">
            <el-cascader
              v-if="
                scope.row.sysMenuList != null &&
                JSON.stringify(scope.row.sysMenuList) != '[]'
              "
              clearable
              size="small"
              :props="{ value: 'id', label: 'title', children: 'children' }"
              placeholder="菜单目录"
              :options="getTypeList(scope.row.sysMenuList)"
              filterable
            />
            <div v-else>无菜单目录</div>
          </template>
        </el-table-column>
        <el-table-column label="可视工具">
          <template #default="scope">
            <el-cascader
              v-if="
                scope.row.sysToolsList != null &&
                JSON.stringify(scope.row.sysToolsList) != '[]'
              "
              clearable
              size="small"
              :props="{ value: 'id', label: 'name', children: 'children' }"
              placeholder="工具目录"
              :options="getTypeList(scope.row.sysToolsList)"
              filterable
            />
            <div v-else>无可视工具</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="110">
          <template #default="scope">
            <el-tooltip
              :content="
                scope.row.status === 1 ? '关闭后此将无法使用' : '确定要开启吗'
              "
              placement="top"
            >
              <el-switch
                @click="setState(scope.row.id, scope.row.status)"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" />
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑
            </el-button>
            <el-button type="text" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          border
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加用户组件 -->
    <add-s-y-s-user ref="add"
        :dept-list="deptList"
        :tree-list="treeList"
    />
    <!-- 编辑用户组件 -->
    <update-s-y-s-user
        ref="update"
        :item="editItem"
        :dept-list="deptList"
        :tree-list="treeList"
    />
  </div>
</template>

<script>
import { listXcxDept } from "@/api/dept";
import { page, updateState } from "../../api/sysUser";
import AddSYSUser from "./component/user/AddSYSUser";
import UpdateSYSUser from "./component/user/UpdateSYSUser";
export default {
  name: "SYSUser",
  components:{
    UpdateSYSUser,
    AddSYSUser
  },
  data() {
    return {
      treeList: [],
      query: { 
        pageIndex: 1,
        pageSize: 10,
        phone: null,
        name: null,
        type: null,   
      },
      tableData: [],
      dept: { parentId: null, name: "", sort: 0, type: 0 },
      deptList: [],
      editItem: {},
    };
  },
  methods: {
    getData() {
      page(this.query).then((val) => {
        this.tableLoading = false;
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      });
    },
    /**
     * 修改用户状态
     */
    setState(id, state) {
      updateState(id, state).then((res) => {
        if (res.code === 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      });
    },
    /**
     * 分页
     */
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    /**
     * 搜索
     */
    handleSearch() {
      this.query.pageIndex = 1;
      this.getData();
    },

    getTypeList(list) {
      if (list == null) return list;
      list.forEach((item) => {
        if (item != null) {
          if (item.children != null && item.children.length > 0) {
            this.getTypeList(item.children);
          } else {
            item.children = null;
          }
        }
      });
      return list;
    },
    /**
     * 添加管理员
     */
    addSYSUser(){
      this.$refs.add.switchDialog();
    },
    /**
     * 编辑管理员
     */
    handleEdit(index, row) {
      this.editItem = row;
      this.$refs.update.switchDialog();
    },
    /**
     * 获取树结构部门数据
     */
    getDept() {
      listXcxDept().then((res) => {
        this.deptList = this.getTypeList(res.data);
      });
    },
  },
  created() {
    //当前用户的菜单列表
    this.treeList = this.getTypeList(JSON.parse(localStorage.getItem('allMenu')));
    this.getDept();
  },
};
</script>

<style scoped>
.sys-user {
  background-color: #ffffff;
  height: 100%;
  display: flex;
}

.sys-user .el-tree {
  height: 100%;
  width: 400px;
  border-right: 3px #ebeef5 solid;
}

.query-item {
  margin: 20px 10px;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}

.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>