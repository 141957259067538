<template>
  <el-dialog
      title="修改管理员信息"
      v-model="updateDialog"
      width="1000px"
  >
    <el-form
        v-loading="updateLogin"
        element-loading-text="请稍等..."
        ref="addRules"
        :model="user"
        style="padding: 20px 40px 20px 20px"
        label-width="90px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item prop="coachName" label="教练姓名">
            <el-input v-model="user.username"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="coachPhone" label="教练电话">
            <el-input v-model="user.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属部门" prop="fatherId">
            <el-cascader
                v-model="user.deptId"
                :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                checkStrictly: true,
                }"
                :options="deptList"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="校区类型">
            <el-select
                placeholder="选择类型"
                v-model="areaType"
                @change="getAreaList"
            >
              <el-option label="全部"></el-option>
              <el-option label="中招体育" value="1"></el-option>
              <el-option label="少年" value="2"></el-option>
              <el-option label="高考体育" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="areaList" label="可视校区">
            <el-cascader
                v-model="user.areaIdList"
                placeholder="请选择区域"
                :options="areaList"
                collapse-tags
                :collapse-tags-tooltip="true"
                clearable
                :props="{
                    multiple: true,
                    value: 'id',
                    label: 'name',
                    children: 'children',
                  }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="areaList" label="可视菜单">
            <el-cascader
                v-model="user.menuIdList"
                placeholder="请选择菜单"
                :options="treeList"
                collapse-tags
                :collapse-tags-tooltip="true"
                clearable
                :props="{
                    multiple: true,
                    value: 'id',
                    label: 'title',
                    children: 'children',
                  }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="可视工具">
            <el-select
                v-model="user.toolsIdList"
                multiple
                collapse-tags
                collapse-tags-tooltip
                placeholder="请选择"
            >
              <el-option
                  v-for="item in toolMenu"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="管辖部门">
            <el-cascader
                v-model="user.deptIdList"
                collapse-tags
                collapse-tags-tooltip
                clearable
                :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                multiple: true
              }"
                :options="administerDeptList"
                @change="relationCheck"
            />
<!--            去掉cascader组件实例的父子不级联选项-->
            <!--  checkStrictly: true,-->
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="updateLogin" @click="updateDialog = false">取 消</el-button>
          <el-button :disabled="updateLogin" type="primary" @click="updateSYSUser()">
            {{ updateLogin === true ? "请稍等..." : "确 定" }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {amendUser} from "@/api/sysUser.js";
import {listAreaTreeAddUser} from "@/api/area.js";
import {getListTree} from "@/api/dept.js";
import {removeInvalidData} from '../../../../utils/methodUtil';

export default {
  name: "UpdateSYSUser",
  props: {
    item: Object,
    deptList: Array,
    treeList: Array,
  },
  data() {
    return {
      updateDialog: false,
      updateLogin: false,
      toolMenu: JSON.parse(localStorage.getItem("toolsMenu")),
      user: {},
      areaType: null,
      areaList: [],
      administerDeptList: []
    }
  },
  methods: {
    /**
     * 获取区域递归级校区
     * @param areaType
     */
    getAreaList() {
      listAreaTreeAddUser(this.areaType).then((val) => {
        if (val.code === 200) {
          this.areaList = removeInvalidData(val.data);
        }
      });
    },
    switchDialog() {
      this.updateDialog = !this.updateDialog
    },
    updateSYSUser() {
      this.updateLogin = true;
      if (Array.isArray(this.user.deptIdList)) {
        this.user.deptIdList = [...this.user.deptIdList.flat()];
      }
      if (Array.isArray(this.user.menuIdList)) {
        this.user.menuIdList = [...this.user.menuIdList.flat()];
      }
      if (Array.isArray(this.user.areaIdList)) {
        this.user.areaIdList = [...this.user.areaIdList.flat()];
      }
      if (Array.isArray(this.user.deptId)) {
        this.user.deptId = this.user.deptId[this.user.deptId.length - 1]
      }
      amendUser(this.user).then(res => {
        this.updateLogin = false;
        if (res.code === 200) {
          this.updateDialog = false;
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      })
    },
    relationCheck(d) {
      console.log("级联选中下级:" + d)
    }
  },
  created() {
    getListTree().then((val) => {
      if (val.code == 200) {
        this.administerDeptList = removeInvalidData(val.data);
      }
    });
  },
  watch: {
    item(newValue) {
      this.user = JSON.parse(JSON.stringify(newValue));
      this.getAreaList();
    },

  }
}
</script>

<style scoped>

</style>