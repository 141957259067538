import request from "../utils/request";

/**
 * 分页
 * @param list
 * @returns {AxiosPromise}
 */
export const page = (list) => {
    return request({
        url: '/sys/sysUser/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 创建管理员用户
 * @param query
 * @returns {AxiosPromise}
 */
export const createUser = (list) => {
    return request({
        url: '/sys/sysUser/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 修改状态
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const updateState = (id, state) => {
    return request({
        url: '/sys/sysUser/updateState',
        method: 'get',
        params: {
            id,
            state
        }
    });
};

//修改超级用户状态
export const amendUser = (list) => {
    return request({
        url: '/sys/sysUser/update/web/user', //api/sys/sysUser/update/web/user
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};