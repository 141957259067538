<template>
  <el-dialog v-model="addDialog" title="添加管理员" width="1000px">
    <el-form
        v-loading="addLogin"
        element-loading-text="请稍等..."
        :rules="userRules"
        style="padding-right: 40px;height: 280px"
        ref="ruleForm"
        :model="user"
        label-width="120px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="用户名称" prop="username">
            <el-input
                v-model="user.username"
                placeholder="请输入名称"
                clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手机号码" prop="phone">
            <el-input
                v-model="user.phone"
                placeholder="请输入手机号"
                clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="用户密码" prop="password">
            <el-input
                v-model="user.password"
                placeholder="请输入密码"
                clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="校区类型">
            <el-select
                collapse-tags
                collapse-tags-tooltip
                placeholder="请选择"
                v-model="areaType"
                @change="getAreaList"
            >
              <el-option label="全部"></el-option>
              <el-option label="中招体育" value="1"></el-option>
              <el-option label="少年" value="2"></el-option>
              <el-option label="高考体育" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="可视校区" prop="areaIdList">
            <el-cascader
                :options="areaList"
                collapse-tags
                :collapse-tags-tooltip="true"
                clearable
                v-model="user.areaIdList"
                :props="{
                multiple: true,
                value: 'id',
                label: 'name',
                children: 'children',
              }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="可视菜单" prop="menuId">
            <el-cascader
                :options="treeList"
                collapse-tags
                collapse-tags-tooltip
                clearable
                v-model="user.menuIdList"
                :props="{
                multiple: true,
                value: 'id',
                label: 'title',
                children: 'children',
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="可视工具">
            <el-select
                v-model="user.toolIdList"
                multiple
                collapse-tags
                collapse-tags-tooltip
                placeholder="请选择"
            >
              <el-option
                  v-for="item in toolMenu"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属部门" prop="deptId">
            <el-cascader
                v-model="user.deptIdSubstitute"
                :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                checkStrictly: true,
              }"
                :options="deptList"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="管辖部门">
            <el-cascader
                @change = 'change'
                ref="cascader"
                v-model="user.deptIdListSubstitute"
                collapse-tags
                collapse-tags-tooltip
                clearable
                :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                checkStrictly: true,
                multiple: true
              }"
                :options="administerDeptList"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="addLogin == true" @click="addDialog = false">取 消</el-button>
          <el-button
              :disabled="addLogin == true"
              type="primary"
              @click="createUser('ruleForm')"
          >{{addLogin == true ? '请稍等...' : '确定'}}</el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {removeInvalidData} from "../../../../utils/methodUtil";
import { listAreaTreeAddUser } from "@/api/area.js";
import { getListTree }from "@/api/dept.js";
import {createUser} from "../../../../api/sysUser";
export default {
  name: "AddSYSUser",
  props: {
    item: Object,
    deptList:Array,
    treeList:Array,
  },
  data() {
    return {
      addDialog:false,
      addLogin:false,
      areaList:[],
      administerDeptList:[],
      areaType:null,
      toolMenu: JSON.parse(localStorage.getItem("toolsMenu")),
      user:{
        areaIdList: [],
        deptIdSubstitute:[],
        deptIdList:null,
        deptIdListSubstitute:[],
        deptId: null,
        menuIdList: [],
        password: null,
        phone: null,
        toolIdList: [],
        username: ""
      },
      userRules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        deptId:[{ required: true, message: "请输选择所属部门", trigger: "blur" }],
        areaIdList: [{ required: true, message: "请选择可视校区", trigger: "blur" }],
        menuIdList: [{ required: true, message: "请选择可视菜单", trigger: "blur" },],
      },
      nodesWithChildren:[]
    }
  },
  methods:{
    // flattenNodes(nodes) {
    //   for (let node of nodes) {
    //     if (node.children && node.children.length > 0) {
    //       this.nodesWithChildren.push(node);
    //       this.flattenNodes(node.children);
    //     }
    //   }
    //   let newList = Array.from(new Set(this.nodesWithChildren))
    //   console.log(newList);
    // },

    // handleCascaderChange() {
    //   this.nodesWithChildren = []; // 清空数组
    //   this.flattenNodes(this.options); // 调用递归函数遍历选项
    //   console.log(this.nodesWithChildren); // 输出含有children的节点数组
    // },
    // change(){ 
    //   const cascader = this.$refs.cascader;
    //   const checkedNodes = cascader.getCheckedNodes();
    //   this.flattenNodes(checkedNodes)
    // },
    /**
     * 获取区域递归级校区
     * @param areaType
     */
    getAreaList() {
      listAreaTreeAddUser(this.areaType).then((val) => {
        if (val.code == 200) {
          this.areaList = removeInvalidData(val.data);
        }
      });
    },

    /**
     * 创建管理员
     * @param val
     */
    createUser(formName) {
      if (Array.isArray(this.user.deptIdSubstitute)){
        this.user.deptId = this.user.deptIdSubstitute[this.user.deptIdSubstitute.length - 1];
      }else {
        this.user.deptId = null;
      }
      this.user.deptIdList = [];
      if (this.user.deptIdListSubstitute.length>0){
        this.user.deptIdListSubstitute.forEach(item => {
          this.user.deptIdList.push(item[item.length-1])
        })
      }

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addLogin = true;
          this.user.menuIdList = [...this.user.menuIdList.flat()];
          this.user.deptIdList = [...this.user.deptIdList.flat()];
          this.user.areaIdList = [...this.user.areaIdList.flat()];
          createUser(this.user).then((val) => {
            this.addLogin = false;
            if (val.code == 200) {
              this.$message.success("添加成功！");
              this.addDialog = false;
            } else {
              this.$message.error("添加失败！");
            }
          });
        }
      });
    },

    /**
     * 父组件通过这个方法打开子的弹窗
     */
    switchDialog(){
      this.addDialog = !this.addDialog;
      if (this.addDialog == true){
        this.getAreaList();
      }
    }
  },
  created() {
    getListTree().then((val) => {
      if (val.code == 200) {
        this.administerDeptList = removeInvalidData(val.data);
      }
    });
  },
}
</script>

<style scoped>

</style>